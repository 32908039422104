import React from 'react';
import './workandeducation.css';

const Workeducation = (props) => {
  return (
    <div className="workeduction_wrapper">

      <div className="info_wrapper">
        <div className="single_wrapper">
        </div>

        <div className="single_wrapper">
          <div className="single_right">
            <div className="txt_info txt_exp">
              <h5>Frontend Platf. Engineering<br /> <span>client:<img src="http://srdjansusa.underconstructioncity.com/images/victorinox.jpg" alt="ITAcademy" />
  </span></h5>
              <p>NAMICS<span>Belgrade, Serbia</span></p>
            </div>
            <div className="edu_logo">
              <img src="http://srdjansusa.underconstructioncity.com/images/namics.jpg" alt="ITAcademy" />
            </div>
          </div>
        </div>
      </div>

    <div className="godina">
      <p>2019</p>
    </div>

    <div className="info_wrapper">
      <div className="single_wrapper">
      </div>

      <div className="single_wrapper">
        <div className="single_right">
          <div className="txt_info txt_exp">
            <h5>Salesforce Commerce Cloud <br />Front End Developer<br /> <span>client:<img src="http://srdjansusa.underconstructioncity.com/images/suitsupply.png" alt="ITAcademy" />
</span></h5>
            <p>WESTUM<span>Belgrade, Serbia</span></p>
          </div>
          <div className="edu_logo">
            <img src="http://srdjansusa.underconstructioncity.com/images/westum.png" alt="ITAcademy" />
          </div>
        </div>
      </div>
    </div>

    <div className="godina">
      <p>2018</p>
    </div>

    <div className="info_wrapper">
      <div className="single_wrapper">
      <div className="single_left">
        <div className="edu_logo">
          <img src="http://srdjansusa.underconstructioncity.com/images/vinca.png" alt="ITAcademy" />
        </div>
        <div className="txt_info txt_edu">
          <h5>JavaScript course certificate</h5>
          <p>Vinča institute<span>Belgrade, Serbia</span></p>
        </div>
      </div>
      <div className="single_left">
        <div className="edu_logo">
          <img src="http://srdjansusa.underconstructioncity.com/images/crossover-logo.svg" alt="ITAcademy" />
        </div>
        <div className="txt_info txt_edu">
          <h5>iOS Swift certificate</h5>
          <p>Crossover<span>Belgrade, Serbia</span></p>
        </div>
      </div>
      </div>

      <div className="single_wrapper">
        <div className="single_right">
          <div className="txt_info txt_exp">
            <h5>Front End Developer <br /> <span>Lead</span></h5>
            <p>HUGE MEDIA<span>Belgrade, Serbia</span></p>
          </div>
          <div className="edu_logo">
            <img src="http://srdjansusa.underconstructioncity.com/images/hugemedia.png" alt="ITAcademy" />
          </div>
        </div>
      </div>
    </div>

    <div className="godina">
      <p>2017</p>
    </div>

    <div className="info_wrapper">
      <div className="single_wrapper">
      </div>
      <div className="single_wrapper">
        <div className="single_right">
          <div className="txt_info txt_exp">
            <h5>Front End Developer</h5>
            <p>HUGE MEDIA<span>Belgrade, Serbia</span></p>
          </div>
          <div className="edu_logo">
            <img src="http://srdjansusa.underconstructioncity.com/images/hugemedia.png" alt="ITAcademy" />
          </div>
        </div>
        <div className="single_right">
          <div className="txt_info txt_exp">
            <h5>Designer</h5>
            <p>HUGE MEDIA<span>Belgrade, Serbia</span></p>
          </div>
          <div className="edu_logo">
            <img src="http://srdjansusa.underconstructioncity.com/images/hugemedia.png" alt="ITAcademy" />
          </div>
        </div>
      </div>
    </div>

    <div className="godina">
      <p>2016</p>
    </div>

    <div className="info_wrapper">
      <div className="single_wrapper">
        <div className="single_left">
          <div className="edu_logo">
            <img src="http://srdjansusa.underconstructioncity.com/images/ITAkademija.png" alt="ITAcademy" />
          </div>
          <div className="txt_info txt_edu">
            <h5>Web design certificate</h5>
            <p>IT Academy<span>Belgrade, Serbia</span></p>
          </div>
        </div>
      </div>
      <div className="single_wrapper">
        <div className="single_right">
          <div className="txt_info txt_exp">
            <h5>Front End Developer <br /> <span>Partner</span></h5>
            <p>GoWeb<span>Belgrade, Serbia</span></p>
          </div>
          <div className="edu_logo">
            <img src="http://srdjansusa.underconstructioncity.com/images/goweb.png" alt="ITAcademy" />
          </div>
        </div>
        <div className="single_right">
          <div className="txt_info txt_exp">
            <h5>Trainee</h5>
            <p>Link Group<span>Belgrade, Serbia</span></p>
          </div>
          <div className="edu_logo">
            <img src="http://srdjansusa.underconstructioncity.com/images/link.png" alt="ITAcademy" />
          </div>
        </div>
      </div>
    </div>

    <div className="godina">
      <p>2015</p>
    </div>

    <div className="info_wrapper">
      <div className="single_wrapper">
        <div className="single_left">
          <div className="edu_logo">
            <img src="http://srdjansusa.underconstructioncity.com/images/ftn.png" alt="ftn" />
          </div>
          <div className="txt_info txt_edu">
            <h5>Master degree in architecture</h5>
            <p>Faculty of Technical Sciences<span>Novi Sad, Serbia</span></p>
          </div>
        </div>
      </div>
      <div className="single_wrapper">
      </div>
    </div>

    <div className="godina">
      <p>2014</p>
    </div>

      <div className="info_wrapper">
        <div className="single_wrapper">
          <div className="single_left">
            <div className="edu_logo">
              <img src="http://srdjansusa.underconstructioncity.com/images/ftn.png" alt="ftn" />
            </div>
            <div className="txt_info txt_eedu">
              <h5>Bachelor degree in architecture</h5>
              <p>Faculty of Technical Sciences<span>Novi Sad, Serbia</span></p>
            </div>
          </div>
        </div>
        <div className="single_wrapper">
        </div>
      </div>

      <div className="godina">
        <p>2013</p>
      </div>

      <div className="vertical_timeline"></div>
    </div>
  )
}

export default Workeducation;
