import React, { Component } from 'react';
import './App.css';
import { NavIntro } from './components/nav/Nav';
import Workeducation from './components/workandeducation/Workandeducation';
import Skills from './components/skills/Skills';
import Projects from './components/projects/Projects';

import { BrowserRouter} from 'react-router-dom';
import { Route, Link, HashRouter } from 'react-router-dom';

class App extends Component {
  state ={
    webdevelopment : [
      {
        "skill": "HTML 5",
        "image": "http://srdjansusa.underconstructioncity.com/images/logos/html5.png",
        "color": {backgroundColor:"#e44d26", width:"95%"},
      	"skilLevel": {left:"85%"},
        "category": "Web development"
      },{
        "skill": "CSS 3",
        "image": "http://srdjansusa.underconstructioncity.com/images/logos/css3.png",
        "color": {backgroundColor:"#1572b6", width:"95%"},
      	"skilLevel": {left:"85%"},
        "category": "Web development"
      },{
        "skill": "JavaScript",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/js.png",
      	"color": {backgroundColor:"#f0dc1b", width:"65%"},
      	"skilLevel": {left:"60%"},
      	"category": "Web development"
      },{
        "skill": "Sass",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/sass.png",
      	"color": {backgroundColor:"#cd669a", width:"95%"},
      	"skilLevel": {left:"85%"},
      	"category": "Web development"
      },{
        "skill": "jQuery",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/jquery.png",
      	"color": {backgroundColor:"#1268ac", width:"65%"},
      	"skilLevel": {left:"60%"},
      	"category": "Web development"
      },{
        "skill": "Wordpress",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/wordpress.png",
      	"color": {backgroundColor:"#21759b", width:"50%"},
      	"skilLevel": {left:"45%"},
      	"category": "Web development"
      },{
        "skill": "Bootstrap",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/bootstrap.png",
      	"color": {backgroundColor:"#62488a", width:"50%"},
      	"skilLevel": {left:"45%"},
      	"category": "Web development"
      },{
        "skill": "React",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/react.png",
      	"color": {backgroundColor:"#61dafb", width:"80%"},
      	"skilLevel": {left:"75%"},
      	"category": "Web development"
      },{
        "skill": "SWIFT",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/swift.png",
      	"color": {backgroundColor:"#f05138", width:"40%"},
      	"skilLevel": {left:"35%"},
      	"category": "Web development"
      },{
        "skill": "SFCC FE",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/sfcc.png",
      	"color": {backgroundColor:"#00a1e1", width:"75%"},
      	"skilLevel": {left:"70%"},
      	"category": "Web development"
      },{
        "skill": "SAP Hybris",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/sap.png",
      	"color": {backgroundColor:"#eab000", width:"45%"},
      	"skilLevel": {left:"30%"},
      	"category": "Web development"
      }
    ],
    git: [
      {
        "skill": "GitHub",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/github.png",
      	"color": {backgroundColor:"#000000", width:"100%"},
      	"skilLevel": {left:"85%"},
      	"category": "Web development"
      }, {
        "skill": "Bitbucket",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/bitbucket.png",
      	"color": {backgroundColor:"#274970", width:"80%"},
      	"skilLevel": {left:"75%"},
      	"category": "Web development"
      }, {
        "skill": "Microsoft Azure",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/azure.png",
      	"color": {backgroundColor:"#2075b8", width:"70%"},
      	"skilLevel": {left:"65%"},
      	"category": "Web development"
      }
    ],
    design: [
      {
        "skill": "Photoshop",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/photoshop.png",
      	"color": {backgroundColor:"#36c2f5", width:"100%"},
      	"skilLevel": {left:"85%"},
      	"category": "Web development"
      }, {
        "skill": "Illustrator",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/illustrator.png",
      	"color": {backgroundColor:"#ff7c00", width:"100%"},
      	"skilLevel": {left:"85%"},
      	"category": "Web development"
      }, {
        "skill": "Google Web Designer",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/googlewebdesigner.png",
      	"color": {backgroundColor:"#f3b500", width:"90%"},
      	"skilLevel": {left:"85%"},
      	"category": "Web development"
      }, {
        "skill": "InDesign",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/indesign.png",
      	"color": {backgroundColor:"#ff4096", width:"50%"},
      	"skilLevel": {left:"45%"},
      	"category": "Web development"
      }, {
        "skill": "InVision",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/invision.png",
      	"color": {backgroundColor:"#ff3366", width:"50%"},
      	"skilLevel": {left:"45%"},
      	"category": "Web development"
      }, {
        "skill": "AutoCAD 2D",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/autocad.png",
      	"color": {backgroundColor:"#ff3366", width:"100%"},
      	"skilLevel": {left:"85%"},
      	"category": "Web development"
      }, {
        "skill": "3ds Max",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/3dsmax.png",
      	"color": {backgroundColor:"#1f6972", width:"60%"},
      	"skilLevel": {left:"50%"},
      	"category": "Web development"
      }, {
        "skill": "Google SketchUp",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/sketchup.png",
      	"color": {backgroundColor:"#e9292f", width:"50%"},
      	"skilLevel": {left:"45%"},
      	"category": "Web development"
      }
    ],
    projectmanagement: [
      {
        "skill": "JIRA",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/jira.png",
      	"color": {backgroundColor:"#214f81", width:"100%"},
      	"skilLevel": {left:"85%"},
      	"category": "Web development"
      }, {
        "skill": "Trello",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/trello.png",
      	"color": {backgroundColor:"#007ec8", width:"100%"},
      	"skilLevel": {left:"85%"},
      	"category": "Web development"
      }
    ],
    other: [
      {
        "skill": "PHP",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/php.png"
      }, {
        "skill": "AJAX",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/ajax.png"
      }, {
        "skill": "MySQL",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/mysql.png"
      }, {
        "skill": "Xcode",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/xcode.png"
      }, {
        "skill": "Zeplin",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/zeplin.png"
      }, {
        "skill": "Sketch",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/sketch.png"
      }, {
        "skill": "Google Analitycs",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/googleanalitycs.png"
      }, {
        "skill": "Mailchimp",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/mailchimp.png"
      }, {
        "skill": "Webpack",
      	"image": "http://srdjansusa.underconstructioncity.com/images/logos/webpack.png"
      }
    ]
  }


  render() {
    return (
      <HashRouter>
        <div id="style1">
          <Route path="/" exact component={Workeducation} />
          <Route path="/projects" exact component={Projects} />
          <Route path="/skills" exact
            render={(routeProps) => (<Skills {...routeProps}
                                        webDev={this.state.webdevelopment}
                                        git={this.state.git}
                                        design={this.state.design}
                                        projectmanagement={this.state.projectmanagement}
                                        other={this.state.other} />)} />
          <NavIntro/>
        </div>
      </HashRouter>
    );
  }
}

export default App;
