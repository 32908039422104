import React from 'react';
import './projects.css';

const Projects = (props) => {
  return (
    <div className="projects">

        <div className="single_project">
          <div className="project_info">
            <h5>CERCLE PROJECT</h5>
            <p>portfolio showcase item</p>
            <p><br/>REACT component UX and FE development showcase <br/><br/> REACT JS / UX / DESIGN / FE DEVELOPMENT</p>
            <a className="site_link" target="_blank" href="http://underconstructioncity.com/#/CercleProject">visit cercle project</a>
          </div>
          <div className="project_visual">
            <img className="gif_item" src="http://srdjansusa.underconstructioncity.com/images/CercleProject.png"/>
          </div>
        </div>


      <div className="single_project">
        <div className="project_info">
          <h5>UNDER CONSTRUCTION CITY</h5>
          <p>Personal project</p>
          <p><br/>Personal blog, currently under maintanance and redevelopment. <br/><br/> REACT JS / UX / DESIGN / FE DEVELOPMENT</p>
          <a className="site_link" target="_blank" href="http://underconstructioncity.com">visit site</a>
        </div>
        <div className="project_visual">
          <img className="genex" src="http://underconstructioncity.com/imgs/panorama/genex450.png" alt="genex" />
          <img className="rudo" src="http://underconstructioncity.com/imgs/panorama/istocna-kapija.png" alt="rudo" />
          <img className="beogradjanka" src="http://underconstructioncity.com/imgs/panorama/beogradjanka.png" alt="beogradjanka" />
          <img className="usce" src="http://underconstructioncity.com/imgs/panorama/usce.png" alt="usce" />
          <img className="toranj" src="http://underconstructioncity.com/imgs/panorama/toranj.png" alt="toranj" />
          <img className="ambasada" src="http://underconstructioncity.com/imgs/panorama/ambasada.png" alt="ambasada" />
          <img className="skupstina" src="http://underconstructioncity.com/imgs/panorama/skupstinaUCP.png" alt="skupstina" />
          <img className="msu" src="http://underconstructioncity.com/imgs/panorama/MSU.png" alt="msu" />
        </div>
      </div>

      <div className="single_project">
        <div className="project_info">
          <h5>lanapavkov.com</h5>
          <p>Movie director personal portfolio</p>
          <p><br/>A creative director approach to designing her own digital presentation. <br/><br/> REACT JS / UX / DESIGN / FE DEVELOPMENT</p>
          <a className="site_link" target="_blank" href="http://lanapavkov.com">visit lanapavkov.com</a>
        </div>
        <div className="project_visual">
          <img className="gif_item" src="http://srdjansusa.underconstructioncity.com/images/lanapavkov.png"/>
        </div>
      </div>

    </div>
  )
}

export default Projects;
