import React from 'react';
import './nav.css';
import { Route, Link } from 'react-router-dom';

export class NavIntro extends React.Component{
  state = {
    hoverStateEnter: false
  };

  constructor(props) {
   super(props);

   this.handleHover = this.handleHover.bind(this);
   this.toggleClass=this.toggleClass.bind(this);
  }

  handleHover = () => {
    this.setState((prevState) => {
      return {hoverStateEnter: !prevState.hoverStateEnter};
    });
    this.setState((prevState) => {
      return {symbolMove: !prevState.symbolMove};
    });
    this.setState((prevState) => {
      return {backgroundMove: !prevState.backgroundMove};
    });
  };

  toggleClass(e){
    this.setState((prevState) => {
      return {hoverStateEnter: !prevState.hoverStateEnter};
    });
    this.setState((prevState) => {
      return {symbolMove: !prevState.symbolMove};
    });
    this.setState((prevState) => {
      return {backgroundMove: !prevState.backgroundMove};
    });
   let classes = 'navbtn';
   let els = document.getElementsByClassName('navbtn activenav');
   if(els){
       while (els[0]) {
           els[0].classList.remove('activenav')
       }
   }
   e.target.className = classes.replace('navbtn','navbtn activenav');
 }

  render() {
    const open_nav = this.state.hoverStateEnter ? "navIntro intro_closed" : "navIntro";
    const arrow_position = this.state.symbolMove ? "fa fa-arrow-left close_nav" : "fa fa-arrow-left open_nav";
    const backgroundMove = this.state.backgroundMove ? "background_cover background_cover_close" : "background_cover";

  return (
/*onClick={this.handleHover}*/
    <div className={backgroundMove}>
      <div className={open_nav} >
      <i onClick={this.handleHover} className={arrow_position}></i>
      <div className="wrapper_nav-image">
        <div className="image_container">
          <img src="http://srdjansusa.underconstructioncity.com/images/srdjan_susa.jpg" alt="srdjan_susa" />
        </div>
        <div className="nav_segments">
          <p  className="navbtn activenav" onClick={(e) =>this.toggleClass(e)}><Link to="/">EDUCATION &amp; EXPERIENCE</Link></p>
          <p  className="navbtn" onClick={(e) =>this.toggleClass(e)}><Link to="/skills">SKILLS</Link></p>
          <p  className="navbtn" onClick={(e) =>this.toggleClass(e)}><Link to="/projects">PROJECTS</Link></p>
        </div>
      </div>

      <div className="wrapper_nav-image">
      <div className="image_container basic_info">
        <h1>Srđan Šuša</h1>
        <div className="name_info">
          <div className="birthday_location">
            <p><i className="fa fa-birthday-cake"></i></p>
            <p>6<sup>th</sup> of July 1989.</p>
          </div>
          <div className="birthday_location">
            <p><i className="fa fa-location-arrow"></i></p>
            <p>Belgrade,<sup> </sup> Serbia</p>
          </div>
        </div>
        <div className="conatct_phone_email">
          <a href="mailto:srdjan_susa@live.com?Subject=Job%20inquiry" target="_blank"><i className="fa fa-envelope-o"></i><span>srdjan_susa@live.com</span></a>
          <a href="tel:+381641266154"><i className="fa fa-mobile"></i><span>+381 (0) 64 1266154</span></a>
        </div>
      </div>
        <div className="nav_segments bottom_segment">
          <div className="social">
            <a href="https://www.facebook.com/srdjan.susa" target="_blank"><i className="fa fa-facebook"></i></a>
            <a href="https://rs.linkedin.com/in/srdjansusa" target="_blank"><i className="fa fa-linkedin"></i></a>
            <a href="https://www.instagram.com/srdjan_susa/?hl=en" target="_blank"><i className="fa fa-instagram"></i></a>
            <a href="https://twitter.com/alpha_susa" target="_blank"><i className="fa fa-twitter"></i></a>
          </div>
        </div>

      </div>
      </div>

    </div>
  );
}
}
