import React from 'react';
import './skills.css';

const Skills = (props) => {
  return (
    <div className="skills">
      <h5>Web &amp; Application Development</h5>
      <div className="groupskills_wrapper">
      {
         props.webDev.map(function (skill, i) {
            return  <div className="single_skill">
                      <div className="full_line"></div>
                      <div style={skill.color} className="progress"></div>
                      <img className="icon_skill" style={skill.skilLevel} src={skill.image} alt={skill.skill}/>
                      <p className="skill_name">{skill.skill}</p>
                    </div>
         })
      }
      </div>

      <h5>Git</h5>
      <div className="groupskills_wrapper">
      {
         props.git.map(function (skill, i) {
            return  <div className="single_skill">
                      <div className="full_line"></div>
                      <div style={skill.color} className="progress"></div>
                      <img className="icon_skill" style={skill.skilLevel} src={skill.image} alt={skill.skill}/>
                      <p className="skill_name">{skill.skill}</p>
                    </div>
         })
      }
      </div>

      <h5>Design</h5>
      <div className="groupskills_wrapper">
      {
         props.design.map(function (skill, i) {
            return  <div className="single_skill">
                      <div className="full_line"></div>
                      <div style={skill.color} className="progress"></div>
                      <img className="icon_skill" style={skill.skilLevel} src={skill.image} alt={skill.skill}/>
                      <p className="skill_name">{skill.skill}</p>
                    </div>
         })
      }
      </div>

      <h5>Project Management</h5>
      <div className="groupskills_wrapper">
      {
         props.projectmanagement.map(function (skill, i) {
            return  <div className="single_skill">
                      <div className="full_line"></div>
                      <div style={skill.color} className="progress"></div>
                      <img className="icon_skill" style={skill.skilLevel} src={skill.image} alt={skill.skill}/>
                      <p className="skill_name">{skill.skill}</p>
                    </div>
         })
      }
      </div>

      <h5>Familiar/Worked With</h5>
      <div className="groupskills_wrapper">
      {
         props.other.map(function (skill, i) {
            return  <div className="single_skill_icon">
                      <img className="icon_skill_only" src={skill.image} alt={skill.skill}/>
                      <p className="skill_name_icon">{skill.skill}</p>
                    </div>
         })
      }
      </div>


    </div>
  )
}

export default Skills;
